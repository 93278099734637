body {
  
  margin: 0;
  background: #F9FAFB;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9FAFB;
  overflow-y: scroll !important;
}



@font-face {
  font-family: 'KrungthaiFast-Bold';
  src: local('KrungthaiFast-Bold'), url(./constants/fonts/KrungthaiFast/KrungthaiFast-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'KrungthaiFast-Light';
  src: local('KrungthaiFast-Light'), url(./constants/fonts/KrungthaiFast/KrungthaiFast-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'KrungthaiFast-Regular';
  src: local('KrungthaiFast-Regular'), url(./constants/fonts/KrungthaiFast/KrungthaiFast-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'KrungthaiFast-UltraLight';
  src: local('KrungthaiFast-UltraLight'), url(./constants/fonts/KrungthaiFast/KrungthaiFast-UltraLight.ttf) format('opentype');
}

